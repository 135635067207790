<template>
    <div>
        <el-card class="box-card" shadow="never" v-loading="loading">
            <div class="d-flex justify-content-between">
                <div>
                    <el-button type="danger" size="large" @click="print()">
                        Print
                    </el-button>
                </div>
                <div class="text-right" v-if="appliation_details.status == 'pending'">
                    <el-button type="success" size="large" @click="openDailog('approved')">
                            Approve
                    </el-button>
                    <el-button type="danger" size="large" @click="openDailog('rejected')">
                        Reject
                    </el-button>
                </div>
                <div class="text-right" v-else>
                    <el-tag type="success" size="large" v-if="appliation_details.status == 'approved'"  plain>
                            Approved
                    </el-tag>
                    <el-tag type="success" size="large" v-else-if="appliation_details.status == 'served'"  plain>
                            Served
                    </el-tag>
                    <el-tag v-else type="danger" size="large">
                        Rejected
                    </el-tag>
                </div>
            </div>
            <div id="printArea">
            <table style="width: 100%; text-align: center; margin-bottom: 15px;">
                <tr>
                    <th>
                        <h2 class="mb-0">অর্ক হেলথ লিমিটেড</h2>
                    </th>
                </tr>
                <tr>
                    <th>
                       <p class="mt-0"> সাধারণ অনুদানের যোগ্য রোগী নির্বাচনী ফরম</p>
                    </th>
                </tr>
            </table>

            <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;" class="bordered">
                <tr>
                    <td>
                        রোগীর নামঃ  {{ appliation_details.patient_name }}
                    </td>
                    <td>
                        পিতার নামঃ  {{ appliation_details.father_name }}
                    </td>
                    <td>
                        গ্রামঃ {{  appliation_details.village }}
                    </td>
                </tr>
                <tr>
                    <td>এন আইডি নাম্বারঃ {{ appliation_details.identity_card_no }}</td>
                    <td>মায়ের নামঃ {{ appliation_details.mother_name }}</td>
                    <td>
                        ইউনিয়ন/ওয়ার্ডঃ {{ appliation_details.ward }}
                    </td>
                </tr>
                <tr>
                    <td>জন্ম তারিখঃ {{appliation_details.dob }}</td>
                    <td></td>
                    <td>থানাঃ {{ appliation_details.town  }}</td>
                </tr>
                <tr>
                    <td>বয়সঃ {{appliation_details.age }}</td>
                    <td>পিতার/পেশাঃ {{ appliation_details.father_profession }}</td>
                    <td>জেলাঃ {{ appliation_details.region }}</td>
                </tr>
                <tr>
                    <td>মোবাইল নাম্বারঃ {{ appliation_details.phone_number }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>

            <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;" class="bordered">
                <thead>
                    <tr>
                        <th>ক্রমি.নং</th>
                        <th>আর্থ সামাজিক অবস্থার বিবরণ</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>১</td>
                        <td>আপনি কি পূর্বে কখনো অনুদান গ্রহণ করেছিলেন? গ্রহণ করলে কতবার করেছেন?</td>
                        <td>{{ appliation_details.prev_welfare_taken ? 'হ্যা, ' + appliation_details.prev_welfare_taken:'না' }}</td>
                    </tr>
                    <tr>
                        <td>২</td>
                        <td>আপনার কি স্বল্পমূল্যে প্রয়োজনীয় দ্রবাদি কিনার জন্য সরকার কর্তৃক প্রদানকৃত ভি.জি.এফ. কার্ড আছে?</td>
                        <td>{{ appliation_details.has_vgf_card?'হ্যা':'না' }}</td>
                    </tr>
                    <tr>
                        <td>৩</td>
                        <td>আপনি প্রাকৃতিক দূ্র্যোগ, ঘূর্ণিঝড়, জলোচ্ছ্বাস, বন্যা, নদীভাঙন ইত্যাদিতে আক্রান্ত?</td>
                        <td>{{ appliation_details.natural_disaster_victim?'হ্যা':'না' }}</td>
                    </tr>
                    <tr>
                        <td>৪</td>
                        <td>আপনার পরিবারের লোকসংখ্যা কতজন?</td>
                        <td>{{ appliation_details.no_of_family_member == 1 ? 'চারজন থেকে বেশি' : 'একজন-চারজন' }}</td>
                    </tr>
                    <tr>
                        <td>৫</td>
                        <td>উপার্জনক্ষম লোকের সংখ্যা কতজন? </td>
                        <td>{{ appliation_details.no_of_income_member == 1 ? 'দুইজন/দুইজন থেকে বেশি' : 'একজন' }}</td>
                    </tr>
                    <tr>
                        <td>৬</td>
                        <td>অধ্যয়নরত সদস্য সংখ্যা কতজন?</td>
                        <td>{{ appliation_details.no_of_study_member == 1 ? 'দুইজন থেকে বেশি' : 'দুইজন/একজন'}}</td>
                    </tr>
                    <tr>
                        <td>৭</td>
                        <td>পারিবারিক মাসিক মোট ব্যয়?</td>
                        <td>{{ appliation_details.monthly_family_expense == 1 ? 'আয় থেকে ব্যয় বেশি' : 'আয় ব্যয় সমান/আয় থেকে ব্যয় কম' }}</td>
                    </tr>
                    <tr>
                        <td>৮</td>
                        <td>আপনি কি ঋণগ্রস্থ (টাকার পরিমাণ)</td>
                        <td>{{ appliation_details.loan_amount == 1 ? 'হ্যা' : 'না' }}</td>
                    </tr>
                    <tr>
                        <td>৯</td>
                        <td>আপনি কি সুদসহ কিস্তি প্রদান করেন? </td>
                        <td>{{ appliation_details.has_installment_with_interest?'হ্যা':'না' }}</td>
                    </tr>
                    <tr>
                        <td>১০</td>
                        <td>আপনার মাসিক মোট ব্যয় কত? </td>
                        <td>{{ appliation_details.your_monthly_expense == 1 ? '৭০০০-১২০০০ টাকা' : '৭০০০ টাকা' }}</td>
                    </tr>
                    <tr>
                        <td>১১</td>
                        <td>আপনার কি নিজস্ব জায়গা জমি আছে?</td>
                        <td>{{ appliation_details.has_own_land?'হ্যা':'না' }}</td>
                    </tr>
                    <tr>
                        <td>১২</td>
                        <td>আপনার কি নিজস্ব বাসা/বাড়ি আছে?</td>
                        <td>{{ appliation_details.has_own_house?'হ্যা': 'না' }}</td>
                    </tr>
                    <tr>
                        <td>১৩</td>
                        <td>আপনি কি ভাড়া বাসায় থাকেন?</td>
                        <td>{{ appliation_details.has_house_rent?'হ্যা': 'না' }}</td>
                    </tr>
                </tbody>
            </table>

            <table style="width: 100%; border-collapse: collapse;margin-bottom: 20px;" class="bordered">
                <thead>
                    <tr>
                        <th>Total Service Amount: <br> {{ appliation_details.total_service_amount }} </th>
                        <th>Patient Has Amount: <br> {{ appliation_details.patient_amount }} </th>
                        <th>Requested Discount: <br> {{ appliation_details.expected_discount_amount }} </th>
                        <th>Duty Doctor's Comment: <br> {{ appliation_details.duty_doctor_comment }} </th>
                    </tr>
                </thead>
            </table>

            <table style="width: 100%; border-collapse: collapse;margin-bottom: 20px; line-height:1.7" class="bordered">
                <thead>
                    <tr>
                        <th>Prepared By: <br> {{ appliation_details.created_user_name }} </th>
                        <th>Check & Recommended By: <br> {{ appliation_details.recommended_by }} </th>
                        <th>Approved By: <br> {{ appliation_details.approved_by }} </th>
                    </tr>
                </thead>
            </table>
        </div>
        </el-card>

        <el-dialog v-model="dailog" title="Application review">

            <el-form
                :model="form"
                label-position="top"
                label-width="100px"
                ref="installment">

                <el-row>
                    <el-col :span="12">
                        <el-form-item  label="Apporved By">
                            <el-select v-model="form.action_taken_by" class="m-2" placeholder="Select User" size="large">
                                <el-option
                                v-for="item in users"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>   
                    <el-col :span="12">
                        <el-form-item label="Verification Proof (attachment)">
                            <input type="file" ref="attachment" id="attachment">
                        </el-form-item>
                    </el-col>
                </el-row>
                
                

            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dailog = false">Cancel</el-button>
                    <el-button type="primary" @click="applicationAction()">
                    Confirm
                    </el-button>
                </span>
                </template>
        </el-dialog>

    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'ApplicationReview',
    data() {
        return {
            dailog: false,
            appliation_details: {},
            status: '',
            form: {
                status: '',
                action_taken_by: '',
            },
            users: [],
            loading: true,
        }
    },

    created() {
        this.getDetails();
    },
    
    methods: {

        getDetails(){
            // this.loading = false;
            axios.get(`/api/v2/welfare/applications/${this.$route.params.id}`)
            .then(res => {
                this.appliation_details = res.data.data;
                this.loading = false;
            })
        },

        getExcutiveUsers() {
            axios.get(`/api/v1/user/list?user_type=executive&limit=-1&platform=backoffice`)
            .then(res => {
                this.users = res.data.data;
            })
        },

        openDailog(status) {
            this.dailog = true;
            this.status = status;
            this.getExcutiveUsers();
        },

        applicationAction() {
            const attachment = this.$refs.attachment.files ? this.$refs.attachment.files[0] : '';

            let form = new FormData();
            form.append('status', this.status);
            form.append('action_taken_by', this.form.action_taken_by);
            form.append('attachment', attachment);
            axios
            .post(`/api/v2/welfare/applications/${this.appliation_details.id}/review`, form)
            .then(res => {
                if (res.data.status_code != 200) {
                    this.$notify({
                    title: 'Failed',
                    message: res.data.message,
                    type: 'error',
                    duration: 2000,
                    });
                } else {
                       this.dailog = false;
                       this.getDetails();
                        this.$notify({
                        title: 'Success',
                        message: "Successfully application submited",
                        type: 'success',
                        duration: 2000,
                    });
                }

            })
            
        },

        print() {
            this.printPage = true;
            setTimeout(() => {
                this.printSetup();
            }, 50);
        },

        printSetup() {
        // Get HTML to print from element
            const prtHtml = document.getElementById('printArea').innerHTML;
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open(
                '',
                '',
                'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
            );

            WinPrint.document.write(
                `<!DOCTYPE html>
                    <html>
                    <head>
                        <title>Application Detail </title>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${prtHtml}
                    </body>
                    </html>
                `);

                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.addEventListener('load', () => {
                    setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                    this.printPage = false;
                    }, 200);
            });
        },
    },
}
</script>
<style scoped>
        table.bordered, table.bordered td, table.bordered th {
            border: 1px solid #ddd;
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        th, td {
            padding: 6px;
        }
</style>